.container {
    margin-block-start: var(--margin-big);
}

.educator_header_section {
    display: grid;
    grid-auto-columns: 1fr;
    align-items: center;

    .cover_image {
        position: relative;
        max-width: 100%;
        border: 1px solid var(--border-muted);
        border-radius: var(--border-radius);
        max-height: 10em;
        height: 100%;
        img {
            margin: 0;
            object-fit: cover;
        }
    }

    .header_content {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: center;
    }

    .header_nav {
        display: grid;
        grid-template-columns: 4.7em 1fr minmax(0, auto);
        grid-template-rows: 1fr;
        gap: var(--padding-small);
        position: relative;
        top: calc(var(--padding) * -1);

        .educator_logo {
            position: relative;
            max-width: 100%;
            height: 4.7em;
            align-self: end;
            img {
                object-fit: contain;
                margin: 0;
                box-shadow: var(--card-box-shadow);
                background: white;
                border: 1px solid var(--border-muted);
            }
        }

        .educator_name {
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            h1 {
                margin: 0;
                font-size: var(--font-size-h2);
                font-weight: var(--font-weight-bold);
                margin-block-start: var(--padding);
            }

            .verified_check_mark {
                fill: var(--cademy-brand-color);
                height: 20px;
                width: 30px;
            }
        }
    }

    @media only screen and (min-width: 600px) {
        .header_nav {
            margin-inline: var(--padding);
            grid-template-columns: 5.625em 1fr minmax(0, auto);
            .educator_logo {
                height: 5.625em;
            }
        }
        .cover_image {
            max-height: 13em;
            min-height: 8.75em;
            height: 100%;
        }
    }

    @media only screen and (min-width: 768px) {
        .cover_image {
            max-height: 17.5em;
            min-height: 11.25em;
            height: 100%;
        }
    }

    @media only screen and (min-width: 992px) {
        .header_nav {
            grid-template-columns: 6.875em 1fr minmax(0, auto);
            .educator_logo {
                height: 6.875em;
            }
        }
        .cover_image {
            max-height: 22.5em;
            min-height: 17.5em;
            height: 100%;
        }
    }

    @media only screen and (max-width: 560px) {
        .action_buttons {
            display: none;
        }
    }

    .action_buttons {
        ul {
            display: flex;
            justify-content: flex-end;
            list-style: none;
            padding: 0;
            margin: 0;
            gap: var(--padding-small);
            height: 100%;
            align-items: center;
        }

        a,
        button {
            gap: var(--padding-small);

            svg {
                width: var(--font-size);
                height: var(--font-size);
            }
        }

        .more_button {
            background: var(--bg-grey);
            border: var(--bg-grey) 0.2px solid;

            &:hover {
                background: var(--bg-1);
            }
        }

        .contact_button {
            svg {
                fill: var(--white);
            }
        }
    }
}

.space_block_start {
    margin-block-start: 20px;
}
